export default function HomePage() {
  return (
    <div className="guest-about w-full p-[2rem]">
      {/* <div className="container">
        <div className="content">
          <p className="subtitle">ELEVATE YOUR TRAVEL JOURNEY</p>
          <h1 className="main-title">Experience The Magic Of Flight!</h1>
          <button className="book-btn">Book A Trip Now</button>
        </div>
        <div className="airplane-image">
          <img src="airplane.png" alt="Airplane" />
        </div>
        <div className="know-more">
          <div className="info">
            <h3>Know More</h3>
            <p>Awesome Places</p>
            <span>Discover The World One Adventure At A Time!</span>
            <button className="more-btn">→</button>
          </div>
          <div className="images">
            <img src="place1.jpg" alt="Place 1" />
            <img src="place2.jpg" alt="Place 2" />
            <img src="place3.jpg" alt="Place 3" />
          </div>
        </div>
      </div> */}
      <div className="know-more">a</div>
      
    </div>
  );
}
